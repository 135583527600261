import { useState } from 'react'
import { Column } from 'react-table'
import { IAudit } from '../../../../../../../app/entities/Audit'
import TableTitle from '../../../../../global/components/table/helpers/table-title/TableTitle'
import TableTopper from '../../../../../global/components/table/helpers/table-topper/TableTopper'
import Table from '../../../../../global/components/table/Table'
import { SECTIONS } from '../../../../../global/constants/audit-on-site-cancellation'
import { formatDate } from '../../../../../global/utils/date/mm-dd-yyyy'
import getStatusForAuditSection from '../../../../utils/get-statuses-for-audit-section'
import { StatusDot } from '../../../common/StatusDot'

function OnSiteCancellationTable({ audits }: { audits: IAudit[] }) {
  const [isOpen, setIsOpen] = useState(true)

  const dailyDvircolumns: Column<IAudit | any>[] = [
    {
      Header: 'Service Date',
      accessor: 'operationDate',
      Cell: ({ row: { original } }) => {
        const date = new Date(original.operationDate)
        const formattedDate = formatDate(date)
        return <>{formattedDate}</>
      },
    },
    {
      Header: 'Order ID',
      accessor: 'orderVehicle.order.workOrderId',
    },
    {
      Header: 'Condition',
      accessor: 'condition',
      Cell: ({ row: { original } }) => {
        const { state } = original as IAudit
        const failure = getStatusForAuditSection(state, [
          SECTIONS.cancellation_condition,
        ])
        return StatusDot(failure)
      },
    },
    {
      Header: 'Reason',
      accessor: 'reason',
      Cell: ({ row: { original } }) => {
        const { state } = original as IAudit
        const failure = getStatusForAuditSection(state, [
          SECTIONS.cancellation_reason,
        ])
        return StatusDot(failure)
      },
    },
    {
      Header: 'Torque Validator',
      accessor: 'torqueStick',
      Cell: ({ row: { original } }) => {
        const { state } = original as IAudit
        const failure = getStatusForAuditSection(state, [
          SECTIONS.cancellation_torque_stick,
        ])
        return StatusDot(failure)
      },
    },
    {
      Header: 'Tech Support',
      accessor: 'techSupport',
      Cell: ({ row: { original } }) => {
        const { state } = original as IAudit
        const failure = getStatusForAuditSection(state, [
          SECTIONS.cancellation_tech_support,
        ])
        return StatusDot(failure)
      },
    },
  ]
  return (
    <div style={{ width: '100%' }}>
      <TableTopper
        isOpen={isOpen}
        onToggleClick={() => setIsOpen(!isOpen)}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <TableTitle>On Site Cancellations</TableTitle>
        </div>
      </TableTopper>
      <Table
        columns={dailyDvircolumns}
        data={audits}
        isLoading={false}
        onRowClick={() => {}}
        isOpen={isOpen}
        isDisplayMode={true}
      />
    </div>
  )
}

export { OnSiteCancellationTable }
