// These sectiosn follow the flow provided in initialVirFormData in

import { AUDIT_PROGRESS } from './audit'

// mobile/src/global/context-providers/vir-provider/initialValues.tsx
const SECTIONS = {
  //torqueWrenchValidator

  //frontVehicleLightsAndIndicators
  front_of_van: 'front_of_van',

  //frontVehicleSuspensionAndUnderbodyShield

  //passengerSideSideMirrors
  passenger_side_interior: 'passenger_side_interior',

  //passengerSideBodyAndDoors
  passenger_van_exterior: 'passenger_van_exterior',

  //passengerSideFuelDoorAndFluids

  //passengerSideRearTireWheelAndRim

  //workArea
  van_interior_work_area: 'van_interior_work_area',
  fire_extinguisher: 'fire_extinguisher',
  cheetah: 'cheetah',
  bartech_serial_number: 'bartech_serial_number',
  torque_wrench_window: 'torque_wrench_window',
  balancing_cup_condition: 'balancing_cup_condition',

  //rearVehicle
  rear_of_van: 'rear_of_van',
  rear_license_plate: 'license_plate',
  rear_interior_cargo_area: 'rear_interior_cargo_area',

  //driverSideFuelDoorAndFluids

  //driverSideRearTireWheelAndRim

  //driverSideFrontTireWheelAndRim

  //driverSideBodyAndDoors
  driver_van_exterior: 'driver_van_exterior',

  //driverSideSideMirrors
  driver_side_interior: 'driver_side_interior',

  //driverSideVehicleCleanliness
  tread_work_shoe: 'tread_work_shoe',
  oil_life_percentage: 'oil_life_percentage',
  van_registration: 'van_registration',
  insurance_card: 'insurance_card',
  gas_card_number: 'gas_card_number',
  first_aid_kit: 'first_aid_kit',
  ansi_osha_warning_triangles: 'ansi_osha_warning_triangles',

  //completed
  completed: AUDIT_PROGRESS.completed,
}

const SECTIONS_ORDER = {
  [SECTIONS.front_of_van]: SECTIONS.passenger_side_interior,
  [SECTIONS.passenger_side_interior]: SECTIONS.passenger_van_exterior,
  [SECTIONS.passenger_van_exterior]: SECTIONS.van_interior_work_area,
  [SECTIONS.van_interior_work_area]: SECTIONS.fire_extinguisher,
  [SECTIONS.fire_extinguisher]: SECTIONS.cheetah,
  [SECTIONS.cheetah]: SECTIONS.bartech_serial_number,
  [SECTIONS.bartech_serial_number]: SECTIONS.torque_wrench_window,
  [SECTIONS.torque_wrench_window]: SECTIONS.balancing_cup_condition,
  [SECTIONS.balancing_cup_condition]: SECTIONS.rear_of_van,
  [SECTIONS.rear_of_van]: SECTIONS.rear_license_plate,
  [SECTIONS.rear_license_plate]: SECTIONS.rear_interior_cargo_area,
  [SECTIONS.rear_interior_cargo_area]: SECTIONS.driver_van_exterior,
  [SECTIONS.driver_van_exterior]: SECTIONS.driver_side_interior,
  [SECTIONS.driver_side_interior]: SECTIONS.tread_work_shoe,
  [SECTIONS.tread_work_shoe]: SECTIONS.oil_life_percentage,
  [SECTIONS.oil_life_percentage]: SECTIONS.van_registration,
  [SECTIONS.van_registration]: SECTIONS.insurance_card,
  [SECTIONS.insurance_card]: SECTIONS.gas_card_number,
  [SECTIONS.gas_card_number]: SECTIONS.first_aid_kit,
  [SECTIONS.first_aid_kit]: SECTIONS.ansi_osha_warning_triangles,
  [SECTIONS.ansi_osha_warning_triangles]: SECTIONS.completed,
}

const TITLES = {
  [SECTIONS.front_of_van]: 'Front of Van',
  [SECTIONS.passenger_side_interior]: 'Passenger Side Interior',
  [SECTIONS.passenger_van_exterior]: 'Passenger Van Exterior',
  [SECTIONS.van_interior_work_area]: 'Van Interior Work Area',
  [SECTIONS.fire_extinguisher]: 'Fire Extinguisher',
  [SECTIONS.cheetah]: 'Ceetah Bead Seater',
  [SECTIONS.bartech_serial_number]: 'Bartech Serial Number',
  [SECTIONS.torque_wrench_window]: 'Torque Wrench Window',
  [SECTIONS.balancing_cup_condition]: 'Balancing Cup Condition',
  [SECTIONS.rear_of_van]: 'Rear of Van',
  [SECTIONS.rear_license_plate]: 'License Plate',
  [SECTIONS.rear_interior_cargo_area]: 'Rear Interior Cargo Area',
  [SECTIONS.driver_van_exterior]: 'Driver Van Exterior',
  [SECTIONS.driver_side_interior]: 'Driver Side Interior',
  [SECTIONS.tread_work_shoe]: 'Tread Work Shoe',
  [SECTIONS.oil_life_percentage]: 'Oil Life Percentage',
  [SECTIONS.van_registration]: 'Van Registration',
  [SECTIONS.insurance_card]: 'Insurance Card',
  [SECTIONS.gas_card_number]: 'Gas Card Number',
  [SECTIONS.first_aid_kit]: 'First Aid Kit',
  [SECTIONS.ansi_osha_warning_triangles]: 'ANSI OSHA Kit',
}

const QUESTIONS = {
  [SECTIONS.front_of_van]:
    'Describe any damage pictured. Please see technician photo(s) for reference.',
  [SECTIONS.passenger_side_interior]: `Is the van's passenger side interior clean?`,
  [SECTIONS.passenger_van_exterior]:
    'Describe any damage pictured. Please see technician photo(s) for reference.',
  [SECTIONS.van_interior_work_area]: `Is the van's interior work-area clean with all equipment present?`,
  [SECTIONS.fire_extinguisher]:
    'Is the fire extinguisher charged and up-to-date?',
  [SECTIONS.cheetah]:
    'Please enter the serial number and in-service date of the Cheetah.',
  [SECTIONS.bartech_serial_number]: 'Please enter the Bartech serial number.',
  [SECTIONS.torque_wrench_window]:
    'Is the torque wrench useable and window clean?',
  [SECTIONS.balancing_cup_condition]:
    'Are the balancing cups present and in good condition?',
  [SECTIONS.rear_of_van]:
    'Describe any damage pictured. Please see technician photo(s) for reference.',
  [SECTIONS.rear_license_plate]:
    'Please confirm the license plate number. Use front and rear photos for reference.',
  [SECTIONS.rear_interior_cargo_area]:
    'Describe any damage pictured. Please see technician photo(s) for reference.',
  [SECTIONS.driver_van_exterior]:
    'Describe any damage pictured. Please see technician photo(s) for reference.',
  [SECTIONS.driver_side_interior]: `Is the van's driver side interior clean?`,
  [SECTIONS.tread_work_shoe]:
    'Does the technician have on slip resistant shoes?',
  [SECTIONS.oil_life_percentage]:
    'Please enter the oil life percentage as a whole number (e.g. 50% is 50).',
  [SECTIONS.van_registration]: `Is the van's vehicle registration pictured and up-to-date?`,
  [SECTIONS.insurance_card]: `Is the van's insurance card pictured and up-to-date?`,
  [SECTIONS.gas_card_number]:
    'Please enter the last 5 digits of the gas card number. Use leading zeros if only three are listed.',
  [SECTIONS.first_aid_kit]:
    'Did the techician take a picture of the first aid kit?',
  [SECTIONS.ansi_osha_warning_triangles]:
    'Did the techician take a picture of the ANSI OSHA kit?',
}

const TREAD_WORK_SHOE_DEFECTS = {
  yes: 'Yes',
  no: 'No',
  needsReplacement: 'Needs Replacement',
}

const PHOTO_INTERIOR_DEFECTS = {
  dirty: 'Dirty',
  doorClosed: 'Door Closed',
  noPictureWrongPicture: 'No Picture / Wrong Picture',
}

const OIL_LIFE_PERCENTAGE_KEY = 'Oil Life Percentage'

const GAS_CARD_NUMBER_KEY = 'Gas Card Number'

const REAR_LICENSE_PLATE_KEY = 'License Plate'

const VAN_REGISTRATION_DEFECTS = {
  noPictureWrongPicture: 'No Picture / Wrong Picture',
  missing: 'Missing',
  outOfDate: 'Out of Date',
  soonToBeExpired: 'Soon to be Expired',
}

const INSURANCE_CARD_DEFECTS = {
  noPictureWrongPicture: 'No Picture / Wrong Picture',
  missing: 'Missing',
  soonToBeExpired: 'Soon to be Expired',
  outOfDate: 'Out of Date',
}

const IMAGE_DEFECTS = {
  noPhoto: 'No Photo',
  blurryBadPhoto: 'Blurry/Bad Photo',
  wrongPhoto: 'Wrong Photo',
}

const VAN_DAMAGE_KEY = 'Van Damage'

const PROMPT_DEFECT = {
  needsReview: 'Failing - Needs Review',
}

const VAN_INTERIOR_WORK_AREA_DEFECTS = {
  missingEquipment: 'Missing Equipment',
  dirty: 'Dirty',
  damaged: 'Damaged',
}

const FIRE_EXTINGUISHER_DEFECTS = {
  expiredNoDate: 'Expired / No Date',
  missingEquipment: 'Missing Equipment',
  emptyTank: 'Empty Tank',
  damaged: 'Damaged',
  noPhoto: 'No Photo',
  blurryBadPhoto: 'Blurry/Bad Photo',
  wrongPhoto: 'Wrong Photo',
}

const BALANCING_CUP_CONDITION_DEFECTS = {
  smallCupMissing: 'Small cup missing',
  largeCupMissing: 'Large cup missing',
  rubberProtectorRingDamagedMissing: 'Rubber protector ring damaged/missing',
  noPhoto: 'No Photo',
  blurryBadPhoto: 'Blurry/Bad Photo',
  wrongPhoto: 'Wrong Photo',
}

const SERIAL_NUMBER_CHEETAH_KEY = 'Serial Number Cheetah'

const IN_SERVICE_DATE_CHEETAH_KEY = 'In-Service Date Cheetah'

const BARTEC_SERIAL_NUMBER_KEY = 'Bartec Serial Number'

const TORQUE_WRENCH_WINDOW_DEFECTS = {
  dirty: 'Dirty',
  noPhoto: 'No Photo',
  blurryBadPhoto: 'Blurry/Bad Photo',
  wrongPhoto: 'Wrong Photo',
}

export {
  QUESTIONS,
  TITLES,
  SECTIONS,
  SECTIONS_ORDER,
  TREAD_WORK_SHOE_DEFECTS,
  PHOTO_INTERIOR_DEFECTS,
  OIL_LIFE_PERCENTAGE_KEY,
  VAN_REGISTRATION_DEFECTS,
  INSURANCE_CARD_DEFECTS,
  IMAGE_DEFECTS,
  VAN_INTERIOR_WORK_AREA_DEFECTS,
  FIRE_EXTINGUISHER_DEFECTS,
  VAN_DAMAGE_KEY,
  SERIAL_NUMBER_CHEETAH_KEY,
  IN_SERVICE_DATE_CHEETAH_KEY,
  BARTEC_SERIAL_NUMBER_KEY,
  TORQUE_WRENCH_WINDOW_DEFECTS,
  BALANCING_CUP_CONDITION_DEFECTS,
  GAS_CARD_NUMBER_KEY,
  REAR_LICENSE_PLATE_KEY,
  PROMPT_DEFECT,
}
