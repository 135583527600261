import styles from './styles.module.css'
import { TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import { IAuditPrompt } from '../../../../global/types/audit'

interface TextBoxDisplayProps {
  setPromptState: Function
  promptState: IAuditPrompt
}

function TextBoxDisplay({ setPromptState, promptState }: TextBoxDisplayProps) {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPromptState({
      ...promptState,
      [e.target.name]: {
        value: e.target.value,
        textProps: promptState[e.target.name].textProps,
      },
    })
  }
  return (
    <div className={styles.textboxContainer}>
      {Object.entries(promptState).map(([key, prompt]) => {
        const textProps = promptState[key].textProps
        return (
          <TextField
            label={key}
            type={textProps.type}
            multiline={textProps.multiline}
            rows={textProps.rows}
            value={prompt.value}
            name={key}
            fullWidth
            onChange={handleChange}
          />
        )
      })}
    </div>
  )
}

export default TextBoxDisplay
