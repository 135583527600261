const AUDIT_PROGRESS = {
  needsCoaching: 'needs-coaching',
  completed: 'completed',
  coached: 'coached',
}

enum AUDIT_KEYS {
  installation = 'installation',
  dvir = 'dvir',
  coaching = 'coaching',
}

const AUDIT_NAMES: { [section in AUDIT_KEYS]: string } = {
  installation: 'Installation',
  dvir: 'DVIR',
  coaching: 'Coaching',
}

const AUDIT_PATHS: { [section in AUDIT_KEYS]: string } = {
  installation: '/audits/installation',
  dvir: '/audits/dvir',
  coaching: '/audits/coaching',
}

export { AUDIT_PROGRESS, AUDIT_NAMES, AUDIT_PATHS, AUDIT_KEYS }
