import { AUDIT_PROGRESS } from './audit'

const SECTIONS = {
  // dvir_time: 'dvir_time',
  van_external_images: 'van_external_images',
  torque_stick: 'torque_stick',
  completed: AUDIT_PROGRESS.completed,
}

const TIME_ISSUES = {
  completedLate: 'Completed after 10AM',
}

const VAN_ISSUES = {
  incorrectVan: 'Incorrect Van',
  damageIdentified: 'Damage Identified - needs review',
}

const TORQUE_STICK_ISSUES = {
  unableToReadScreen: 'Unable to Read Screen',
  wrongPhoto: 'Wrong Photo',
  redLight: 'Red Light',
  broken: 'Broken',
  outOfCalibration: 'Out of Calibration (±10%)',
  noPhoto: 'No Photo / Black Photo',
}

const SECTIONS_ORDER = {
  // [SECTIONS.dvir_time]: SECTIONS.van_external_images,
  [SECTIONS.van_external_images]: SECTIONS.torque_stick,
  [SECTIONS.torque_stick]: SECTIONS.completed,
}

const TITLES = {
  // [SECTIONS.dvir_time]: 'Daily DVIR',
  [SECTIONS.van_external_images]: 'DVIR Van',
  [SECTIONS.torque_stick]: 'DVIR Torque Stick',
}

const QUESTIONS = {
  // [SECTIONS.dvir_time]: 'Was the DVIR completed before 10:00 AM local time?',
  [SECTIONS.van_external_images]: 'Exterior photos.',
  [SECTIONS.torque_stick]:
    'Did the technician record a pass of the Mountz validator?',
}

export {
  TIME_ISSUES,
  VAN_ISSUES,
  TORQUE_STICK_ISSUES,
  QUESTIONS,
  SECTIONS,
  SECTIONS_ORDER,
  TITLES,
}
