import { AUDIT_PROGRESS } from './audit'

const SECTIONS = {
  pre_install_front_passenger: 'pre-install-front-passenger',
  pre_install_front_driver: 'pre-install-front-driver',
  pre_install_rear_driver: 'pre-install-rear-driver',
  pre_install_rear_passenger: 'pre-install-rear-passenger',
  order_details_work_area: 'order-details-work-area',
  order_details_torque_stick: 'order-details-torque-stick',
  post_install_front_passenger: 'post-install-front-passenger',
  post_install_front_driver: 'post-install-front-driver',
  post_install_rear_driver: 'post-install-rear-driver',
  post_install_rear_passenger: 'post-install-rear-paenger',
  completed: AUDIT_PROGRESS.completed,
}

const SECTIONS_ORDER = {
  [SECTIONS.pre_install_front_passenger]: SECTIONS.pre_install_front_driver,
  [SECTIONS.pre_install_front_driver]: SECTIONS.pre_install_rear_driver,
  [SECTIONS.pre_install_rear_driver]: SECTIONS.pre_install_rear_passenger,
  [SECTIONS.pre_install_rear_passenger]: SECTIONS.order_details_work_area,
  [SECTIONS.order_details_work_area]: SECTIONS.order_details_torque_stick,
  [SECTIONS.order_details_torque_stick]: SECTIONS.post_install_front_passenger,
  [SECTIONS.post_install_front_passenger]: SECTIONS.post_install_front_driver,
  [SECTIONS.post_install_front_driver]: SECTIONS.post_install_rear_driver,
  [SECTIONS.post_install_rear_driver]: SECTIONS.post_install_rear_passenger,
  [SECTIONS.post_install_rear_passenger]: SECTIONS.completed,
}

const INSTALLATION_ISSUES = {
  blurryBadPhoto: 'Blurry/Bad Photo',
  wrongPhoto: 'Wrong Photo',
  noPhoto: 'No Photo',
  preExistingDamageNotPictured: 'Pre-existing Damage Not Pictured',
}

const WORK_AREA_ISSUES = {
  noWorkAreaPhoto: 'No Work Area Picture',
  noSecondWorkAreaPhoto: 'No Second Work Area Picture',
  bagsMissingMisplaced: 'Bags Missing/Misplaced',
  noJacks: 'No Jacks',
  jackHandleDown: 'Jack Handle Down',
  jackNotLiftedToVehicle: 'Jack Not Lifted to Vehicle',
  incorrectJackPositioning: 'Incorrect Jack Positioning',
  unacceptableWorkSurfaceClearance:
    'Unacceptable Work Surface/Inappropriate Clearance',
}

const TORQUE_STICK_ISSUES = {
  noPhoto: 'No Photo',
  wrongPhoto: 'Wrong Photo',
  incorrectTorqueSetting: 'Incorrect Torque Setting',
  incorrectTorqueStickUsed: 'Incorrect Torque Stick Used',
  incorrectTorqueStickWrenchAssembly:
    'Incorrect Torque Stick and Wrench Assembly',
  needsReplacement: 'Needs Replacement (Damaged/Rusty)',
}

const TITLES = {
  [SECTIONS.pre_install_front_passenger]: 'Pre-Installation Front Passenger',
  [SECTIONS.pre_install_front_driver]: 'Pre-Installation Front Driver',
  [SECTIONS.pre_install_rear_driver]: 'Pre-Installation Rear Driver',
  [SECTIONS.pre_install_rear_passenger]: 'Pre-Installation Rear Passenger',
  [SECTIONS.order_details_work_area]: 'Order Details Work Area',
  [SECTIONS.post_install_front_passenger]: 'Post-Installation Front Passenger',
  [SECTIONS.post_install_front_driver]: 'Post-Installation Front Driver',
  [SECTIONS.post_install_rear_driver]: 'Post-Installation Rear Driver',
  [SECTIONS.post_install_rear_passenger]: 'Post-Installation Rear Passenger',
}

const QUESTIONS = {
  [SECTIONS.pre_install_front_passenger]:
    'Is there a pre-installation front passenger photo?',
  [SECTIONS.pre_install_front_driver]:
    'Is there a pre-installation front driver photo?',
  [SECTIONS.pre_install_rear_driver]:
    'Is there a pre-installation rear driver photo?',
  [SECTIONS.pre_install_rear_passenger]:
    'Is there a pre-installation rear passenger photo?',
  [SECTIONS.order_details_work_area]: `Does the technician's work area meet the standard?`,
  [SECTIONS.order_details_torque_stick]:
    'Did the technician take an image of the Torque Wrench Setting?',
  [SECTIONS.post_install_front_passenger]:
    'Is there a post installation front passenger photo?',
  [SECTIONS.post_install_front_driver]:
    'Is there a post installation front driver photo?',
  [SECTIONS.post_install_rear_driver]:
    'Is there a post installation rear driver photo?',
  [SECTIONS.post_install_rear_passenger]:
    'Is there a post installation rear passenger photo?',
}

export {
  INSTALLATION_ISSUES,
  WORK_AREA_ISSUES,
  TORQUE_STICK_ISSUES,
  QUESTIONS,
  SECTIONS,
  SECTIONS_ORDER,
  TITLES,
}
