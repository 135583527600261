import { useContext, useEffect, useState } from 'react'
import { Column } from 'react-table'
import Table from '../../../global/components/table/Table'
import { IAudit } from '../../../../../app/entities/Audit'
import { handleColumnFilterUpdate } from '../../../global/components/table/helpers/multiColumnFilters'
import retrieveData from '../../../global/components/table/helpers/retrieveData'
import BasicFilter from '../../../global/components/table/helpers/BasicFilter'
import AssignUserCell from '../../../global/components/assign-user/AssignUserCell'
import { PERMISSIONS } from '../../../global/constants/permissions'
import hasPermission from '../../../global/utils/user/has-permission'
import { UserContext } from '../../../base/components/base-container/BaseContainer'
import { fetchData } from '../../../global/utils/fetch'
import { assignUserToAudit, getAuditAssignToUserList } from '../api'
import { IUser } from '../../../../../app/entities/User'
import StartCell from '../common/start/Start'
import NoFilter from '../../../global/components/table/helpers/NoFilter'
import { ILocationFilters } from '../../main/AuditsMain'
import { formatDate } from '../../../global/utils/date/mm-dd-yyyy'
import { STATUS } from '../../../global/types/audit'
import AuditModal from '../audit-modal/AuditModal'

async function fetchAssignToUserList(setUserList: Function) {
  const users = await fetchData<IUser[]>(
    getAuditAssignToUserList(
      PERMISSIONS.AUDITS.ENTRIES.DVIR_AUDITS_ASSIGNEE.NAME,
    ),
  )
  setUserList(users)
}

interface IDvirAuditsTableProps {
  setRefetchIndex: Function
  refetchIndex: number
  locationFilters: ILocationFilters
}

function DvirAuditsTable(props: IDvirAuditsTableProps) {
  const { setRefetchIndex, refetchIndex, locationFilters } = props
  const { user } = useContext(UserContext)
  const [usersList, setUsersList] = useState<IUser[]>([])
  const [dvirAuditModalOpen, setDvirAuditModalOpen] = useState(false)
  const [tableState, setTableState] = useState({
    data: [],
    isLoading: false,
    offSet: 0,
    pageSize: 100,
    count: 0,
  })
  const [columnFilters, setColumnFilters] = useState({})
  const dvirTableEndpoint = `${process.env.REACT_APP_ORIGIN}/audit/dvir?`

  function onColumnFilterUpdate(filtersUpdate: object) {
    handleColumnFilterUpdate(filtersUpdate, columnFilters, setColumnFilters)
  }

  function retrieveTableData() {
    retrieveData(setTableState, {
      endpoint: dvirTableEndpoint,
      pageSize: tableState.pageSize,
      offSet: tableState.offSet,
      columnFilters,
      selectedLocationId: locationFilters.locationFilter,
    })
  }

  useEffect(() => {
    retrieveTableData()
  }, [refetchIndex, locationFilters, tableState.pageSize, tableState.offSet])

  useEffect(() => {
    fetchAssignToUserList(setUsersList)
  }, [])

  const columns: Column<IAudit | any>[] = [
    {
      Header: 'Assigned To',
      accessor: 'assignedToAudit',
      Filter: BasicFilter({
        onChange: (val: string) =>
          onColumnFilterUpdate({ assignedToAudit: val }),
        onEnter: retrieveTableData,
      }),
      Cell: ({ row: { original } }) => {
        const { assignedToAudit, objectId } = original as any
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <AssignUserCell
              assignedTo={assignedToAudit}
              usersList={usersList}
              objectId={objectId}
              retrieveTableData={retrieveTableData}
              disabled={
                !hasPermission(user).allow(
                  PERMISSIONS.AUDITS.CATEGORY,
                  PERMISSIONS.AUDITS.ENTRIES.DVIR_AUDITS_ASSIGNER.NAME,
                )
              }
              assignUserTo={assignUserToAudit}
            />
          </div>
        )
      },
    },
    {
      Header: 'DVIR Date',
      accessor: 'operationDate',
      Filter: NoFilter(),
      Cell: ({ row: { original } }) => {
        const date = new Date(original.operationDate)
        const formattedDate = formatDate(date)
        return <>{formattedDate}</>
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: BasicFilter({
        onChange: (val: string) => onColumnFilterUpdate({ status: val }),
        onEnter: retrieveTableData,
      }),
      Cell: ({ row: { original } }) => {
        const isWeekly = original.status === STATUS.WEEKLY_DVIR
        const displayStatus = isWeekly ? 'Weekly' : 'Daily'
        return <>{displayStatus}</>
      },
    },
    {
      Header: '',
      accessor: 'progress',
      Filter: NoFilter(),
      Cell: ({ row: { original } }) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <StartCell
              // This is the component from within which we handle the data query
              // and modal opening
              audit={original}
              setModalOpen={setDvirAuditModalOpen}
              disabled={
                !hasPermission(user).allow(
                  PERMISSIONS.AUDITS.CATEGORY,
                  PERMISSIONS.AUDITS.ENTRIES.PERFORM_DVIR_AUDITS.NAME,
                )
              }
            />
          </div>
        )
      },
    },
  ]

  return (
    <div style={{ width: '100%' }}>
      <AuditModal
        isOpen={dvirAuditModalOpen}
        setIsOpen={setDvirAuditModalOpen}
        refetchIndex={refetchIndex}
        setRefetchIndex={setRefetchIndex}
      />
      <Table
        columns={columns}
        data={tableState.data ?? []}
        onRowClick={() => {}}
        isLoading={tableState.isLoading}
        isPaginated
        pagination={{ setTableState, tableState }}
        isTableTopperPresent={false}
      />
    </div>
  )
}

export default DvirAuditsTable
