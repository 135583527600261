import { IOrderVehicle } from '../../../../../../app/entities/OrderVehicle'
import {
  INSTALLATION_ISSUES,
  QUESTIONS,
  SECTIONS,
  TITLES,
  TORQUE_STICK_ISSUES,
  WORK_AREA_ISSUES,
} from '../../../../global/constants/audit-completed-installation'
import {
  ORDER_DETAILS_IMAGE_TYPES,
  POST_INSTALL_IMAGE_TYPES,
  PRE_INSTALL_IMAGE_TYPES,
} from '../../../../global/constants/image'
import { IAuditSectionData } from '../../../../global/types/audit'

export function createCompletedInstallationAuditData(
  orderVehicle: IOrderVehicle | null,
): {
  [key: string]: IAuditSectionData
} {
  if (!orderVehicle) return {}
  const { tiresCombination } = orderVehicle

  const torqueValue =
    tiresCombination?.rearTire?.torque ||
    tiresCombination?.frontTire?.torque ||
    'N/A'

  return {
    [SECTIONS.pre_install_front_passenger]: {
      title: TITLES[SECTIONS.pre_install_front_passenger],
      imageTypes: [
        PRE_INSTALL_IMAGE_TYPES.pre_front_passenger,
        PRE_INSTALL_IMAGE_TYPES.pre_front_passenger_damage,
      ],
      question: QUESTIONS[SECTIONS.pre_install_front_passenger],
      defects: {
        [INSTALLATION_ISSUES.blurryBadPhoto]: false,
        [INSTALLATION_ISSUES.wrongPhoto]: false,
        [INSTALLATION_ISSUES.noPhoto]: false,
        [INSTALLATION_ISSUES.preExistingDamageNotPictured]: false,
      },
    },
    [SECTIONS.pre_install_front_driver]: {
      title: TITLES[SECTIONS.pre_install_front_driver],
      imageTypes: [
        PRE_INSTALL_IMAGE_TYPES.pre_front_driver,
        PRE_INSTALL_IMAGE_TYPES.pre_front_driver_damage,
      ],
      question: QUESTIONS[SECTIONS.pre_install_front_driver],
      defects: {
        [INSTALLATION_ISSUES.blurryBadPhoto]: false,
        [INSTALLATION_ISSUES.wrongPhoto]: false,
        [INSTALLATION_ISSUES.noPhoto]: false,
        [INSTALLATION_ISSUES.preExistingDamageNotPictured]: false,
      },
    },
    [SECTIONS.pre_install_rear_driver]: {
      title: TITLES[SECTIONS.pre_install_rear_driver],
      imageTypes: [
        PRE_INSTALL_IMAGE_TYPES.pre_rear_driver,
        PRE_INSTALL_IMAGE_TYPES.pre_rear_driver_damage,
      ],
      question: QUESTIONS[SECTIONS.pre_install_rear_driver],
      defects: {
        [INSTALLATION_ISSUES.blurryBadPhoto]: false,
        [INSTALLATION_ISSUES.wrongPhoto]: false,
        [INSTALLATION_ISSUES.noPhoto]: false,
        [INSTALLATION_ISSUES.preExistingDamageNotPictured]: false,
      },
    },
    [SECTIONS.pre_install_rear_passenger]: {
      title: TITLES[SECTIONS.pre_install_rear_passenger],
      imageTypes: [
        PRE_INSTALL_IMAGE_TYPES.pre_rear_passenger,
        PRE_INSTALL_IMAGE_TYPES.pre_rear_passenger_damage,
      ],
      question: QUESTIONS[SECTIONS.pre_install_rear_passenger],
      defects: {
        [INSTALLATION_ISSUES.blurryBadPhoto]: false,
        [INSTALLATION_ISSUES.wrongPhoto]: false,
        [INSTALLATION_ISSUES.noPhoto]: false,
        [INSTALLATION_ISSUES.preExistingDamageNotPictured]: false,
      },
    },
    [SECTIONS.order_details_work_area]: {
      title: TITLES[SECTIONS.order_details_work_area],
      imageTypes: [
        ORDER_DETAILS_IMAGE_TYPES.left_work_area,
        ORDER_DETAILS_IMAGE_TYPES.right_work_area,
      ],
      question: QUESTIONS[SECTIONS.order_details_work_area],
      defects: {
        [WORK_AREA_ISSUES.noWorkAreaPhoto]: false,
        [WORK_AREA_ISSUES.noSecondWorkAreaPhoto]: false,
        [WORK_AREA_ISSUES.bagsMissingMisplaced]: false,
        [WORK_AREA_ISSUES.noJacks]: false,
        [WORK_AREA_ISSUES.jackHandleDown]: false,
        [WORK_AREA_ISSUES.jackNotLiftedToVehicle]: false,
        [WORK_AREA_ISSUES.incorrectJackPositioning]: false,
        [WORK_AREA_ISSUES.unacceptableWorkSurfaceClearance]: false,
      },
    },
    [SECTIONS.order_details_torque_stick]: {
      title: `Torque Spec: ${torqueValue} ft-lbs`,
      imageTypes: [
        ORDER_DETAILS_IMAGE_TYPES.torque_wrench_setting,
        ORDER_DETAILS_IMAGE_TYPES.first_lug_first_torque,
      ],
      question: QUESTIONS[SECTIONS.order_details_torque_stick],
      defects: {
        [TORQUE_STICK_ISSUES.noPhoto]: false,
        [TORQUE_STICK_ISSUES.wrongPhoto]: false,
        [TORQUE_STICK_ISSUES.incorrectTorqueSetting]: false,
        [TORQUE_STICK_ISSUES.incorrectTorqueStickUsed]: false,
        [TORQUE_STICK_ISSUES.incorrectTorqueStickWrenchAssembly]: false,
        [TORQUE_STICK_ISSUES.needsReplacement]: false,
      },
    },
    [SECTIONS.post_install_front_passenger]: {
      title: TITLES[SECTIONS.post_install_front_passenger],
      imageTypes: [
        POST_INSTALL_IMAGE_TYPES.post_front_passenger,
        PRE_INSTALL_IMAGE_TYPES.pre_front_passenger_damage,
      ],
      question: QUESTIONS[SECTIONS.post_install_front_passenger],
      defects: {
        [INSTALLATION_ISSUES.blurryBadPhoto]: false,
        [INSTALLATION_ISSUES.wrongPhoto]: false,
        [INSTALLATION_ISSUES.noPhoto]: false,
        [INSTALLATION_ISSUES.preExistingDamageNotPictured]: false,
      },
    },
    [SECTIONS.post_install_front_driver]: {
      title: TITLES[SECTIONS.post_install_front_driver],
      imageTypes: [
        POST_INSTALL_IMAGE_TYPES.post_front_driver,
        PRE_INSTALL_IMAGE_TYPES.pre_front_driver_damage,
      ],
      question: QUESTIONS[SECTIONS.post_install_front_driver],
      defects: {
        [INSTALLATION_ISSUES.blurryBadPhoto]: false,
        [INSTALLATION_ISSUES.wrongPhoto]: false,
        [INSTALLATION_ISSUES.noPhoto]: false,
        [INSTALLATION_ISSUES.preExistingDamageNotPictured]: false,
      },
    },
    [SECTIONS.post_install_rear_driver]: {
      title: TITLES[SECTIONS.post_install_rear_driver],
      imageTypes: [
        POST_INSTALL_IMAGE_TYPES.post_rear_driver,
        PRE_INSTALL_IMAGE_TYPES.pre_rear_driver_damage,
      ],
      question: QUESTIONS[SECTIONS.post_install_rear_driver],
      defects: {
        [INSTALLATION_ISSUES.blurryBadPhoto]: false,
        [INSTALLATION_ISSUES.wrongPhoto]: false,
        [INSTALLATION_ISSUES.noPhoto]: false,
        [INSTALLATION_ISSUES.preExistingDamageNotPictured]: false,
      },
    },
    [SECTIONS.post_install_rear_passenger]: {
      title: TITLES[SECTIONS.post_install_rear_passenger],
      imageTypes: [
        POST_INSTALL_IMAGE_TYPES.post_rear_passenger,
        PRE_INSTALL_IMAGE_TYPES.pre_rear_passenger_damage,
      ],
      question: QUESTIONS[SECTIONS.post_install_rear_passenger],
      defects: {
        [INSTALLATION_ISSUES.blurryBadPhoto]: false,
        [INSTALLATION_ISSUES.wrongPhoto]: false,
        [INSTALLATION_ISSUES.noPhoto]: false,
        [INSTALLATION_ISSUES.preExistingDamageNotPictured]: false,
      },
    },
  }
}
