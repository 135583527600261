import Button from '@mui/material/Button'
import { useContext } from 'react'
import { IAudit } from '../../../../../../app/entities/Audit'
import { AUDIT_PROGRESS } from '../../../../global/constants/audit'
import { AuditContext } from '../../../../global/context/audit-context/AuditContext'

interface IStartCellProps {
  audit: IAudit
  setModalOpen: Function
  disabled: boolean
}

function StartCell({ audit, setModalOpen, disabled }: IStartCellProps) {
  const { progress, state } = audit
  const needsCoaching = progress === AUDIT_PROGRESS.needsCoaching
  let isInProgress = Boolean(progress)

  if (needsCoaching && state) {
    isInProgress = Object.values(state).some(
      (section) => section.coaching.needsCoaching && section.coaching.coached,
    )
  }

  const { queryAuditData } = useContext(AuditContext)

  function onClickHandler() {
    queryAuditData(audit.objectId)
    setModalOpen(true)
  }

  return (
    <div>
      <Button
        onClick={() => onClickHandler()}
        variant='outlined'
        size={'small'}
        color={'inherit'}
        style={{ textTransform: 'none' }}
        sx={{
          color: isInProgress ? '#D7ABFF' : '#D70000',
          '&:hover': { backgroundColor: isInProgress ? '#F5EAFF' : '#d69090' },
        }}
        disabled={disabled}
      >
        {isInProgress ? 'Resume' : 'Start'}
      </Button>
    </div>
  )
}

export default StartCell
